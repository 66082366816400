<template lang="pug">
.application-factoring-data-form-fields
  .row.animated.fadeIn
    .col-lg-6
      fi-form-field(
        :label     = '$t("limit")'
        label-cols = 3
      )
        fi-money-input.form-control.form-control-sm.animated.fadeIn(
          type         = 'text'
          v-model.trim = 'factoringData.contractLimit.$model'
        )
      fi-form-field(
        :label     = '$t("advanceRate")'
        label-cols = 3
      )
        percent-input.form-control.form-control-sm.animated.fadeIn(
          type         = 'text'
          v-model.trim = 'factoringData.advanceRate.$model'
        )
      fi-form-field(
        :label     = '$t("invoiceLength")'
        label-cols = 3
      )
        .row
          .input-group.col.input-group-sm
            .input-group-prepend
              span.input-group-text {{ $t('common:min') }}
            input.form-control.form-control-sm.animated.fadeIn(
              type           = 'text'
              v-model.number = 'factoringData.invoiceLengthMin.$model'
            )
          .input-group.col.input-group-sm
            .input-group-prepend
              span.input-group-text {{ $t('common:max') }}
            input.form-control.form-control-sm.animated.fadeIn(
              type           = 'text'
              v-model.number = 'factoringData.invoiceLengthMax.$model'
            )
      fi-form-field(
        :label     = '$t("interestRate")'
        label-cols = 3
      )
        application-factoring-interest-rate-item(
          v-for                 = '(interestRate, index) in factoringData.invoiceLengthInterestRates.$model'
          :key                  = 'index'
          :interest-rate        = 'interestRate'
          :day-count-convention = 'productInterestRate.dayCountConvention'
          @input                = 'updateInterestRate($event, index)'
        )
          .col-2.px-1
            button.btn.btn-danger.btn-sm(
              v-if           = 'index'
              @click.prevent = 'updateInterestRate(undefined, index)'
            ): i.far.fa-trash-alt.fa-lg
            button.btn.btn-primary.btn-sm(
              v-else
              @click.prevent = 'addInterestRate'
            ): i.far.fa-plus-square.fa-lg

    .col-lg-6
      fi-form-field(
        :label     = '$t("period")'
        :state     = '!factoringData.loanLength.$error'
        label-cols = 3
      )
        template(#error)
          .error-message(v-if = '!factoringData.loanLength.required') {{ $t('common:required') }}

        v-loading(loader = 'application:product:fetch')
          template(#spinner): i.fa.fa-spinner.fa-pulse
          .row
            .col
              input.form-control.form-control-sm(
                v-model.number = 'factoringData.loanLength.$model'
                type           = 'text'
                :class         = '{ "is-invalid": factoringData.loanLength.$error }'
              )
      fi-form-field(
        :label     = '$t("recourse")'
        label-cols = 3
      )
        fi-switch(v-model = 'factoringData.rightOfRecourse.$model')
  .row
    .col.d-flex.justify-content-end
      button.btn.btn-primary(@click.prevent ='$emit("apply", factoringData.$model)') {{ $t('applyAll') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import FiSwitch from '@/components/FiSwitch'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'

export default {
  name: 'application-factoring-data-fields',

  components: { ApplicationFactoringInterestRateItem, FiSwitch, PercentInput, FiMoneyInput, FiFormField },

  props: {
    factoringData: {
      type: Object,
      required: true
    },
    productAttributes: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    productInterestRate () {
      return this.productAttributes?.INTEREST_RATE ?? {}
    }
  },

  methods: {
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.factoringData.invoiceLengthInterestRates.$model.splice(index, 1)
      } else {
        this.factoringData.invoiceLengthInterestRates.$model.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.factoringData.invoiceLengthInterestRates.$model.push({
        invoiceLength: this.productAttributes?.INVOICE_LENGTH_IN_DAYS?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.productAttributes?.INTEREST_RATE?.defaultValue
      })
    }
  }
}
</script>


<i18n>
en:
  limit:         "Contract limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  applyAll:      "Apply to all"
  period:        "Contract period"
  recourse:      "Right of recourse"
  interestRate:  "Interest rate"
et:
  limit:         "Contract limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  applyAll:      "Apply to all"
  period:        "Contract period"
  recourse:      "Right of recourse"
  interestRate:  "Interest rate"
ru:
  limit:         "Contract limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  applyAll:      "Apply to all"
  period:        "Contract period"
  recourse:      "Right of recourse"
  interestRate:  "Interest rate"
</i18n>
