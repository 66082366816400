<template lang="pug">
form.form-horizontal.application-new(
  @submit.prevent = 'onSubmit'
  novalidate
)
  .card.mb-0.border-bottom-0
    .card-header {{ $t('title') }} - {{ workflowPolicy.productType.human }}
    .card-body
      .row: .col-lg-6
        fi-form-field(
          :label = '$t("product")'
          :state = '!$v.applicationObj.applicationFields.productId.$error'
        )
          template(#label)
            i.fa.fa-spinner.fa-pulse.fa-pull-right(v-if = '$vueLoading.isLoading("products:fetch")')
          template(#error)
            .error-message(v-if = '!$v.applicationObj.applicationFields.productId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = '$v.applicationObj.applicationFields.productId.$model'
            :options       = 'allowedProductOptions'
            :disabled      = '$vueLoading.isLoading("products:fetch")'
            :placeholder   = '$t("productPlaceholder")'
            :class         = '{ "is-invalid": $v.applicationObj.applicationFields.productId.$error }'
            required
            sm
          )
        fi-form-field.animated.fadeIn(
          v-if   = 'factoringProduct.creditIssuerId'
          :label = '$t("creditIssuer")'
        ) {{ creditIssuer.text }}

  .card.mb-0.border-bottom-0
    .card-header {{ $t('customerTitle') }}
    application-customer-form-fields(
      v-if           = 'workflowPolicy.privateCustomer'
      :customer-data = '$v.applicationObj.privateApplication'
      :product       = 'factoringProduct'
      :segments      = 'allowedSegments'
      :sectors       = 'allowedSectors'
      :id-doc-expire = 'idDocExpireDate'
      @input         = 'selectCustomer'
    )
    application-corporate-customer-form(
      v-else
      :customer-data = '$v.applicationObj.corporateApplication'
      :product       = 'factoringProduct'
      :segments      = 'allowedSegments'
      :sectors       = 'allowedSectors'
      @input         = 'selectCustomer'
    )

  .card.mb-0.border-bottom-0
    .card-header {{ $t('dataTitle') }}
    .card-body
      application-factoring-data-fields(
        :factoring-data     = '$v.applicationObj.dataFields'
        :product-attributes = 'factoringProductAttributes'
        @apply              = 'applyToDebtors'
      )

  application-factoring-third-party-list(
    v-if              = 'workflowPolicy.isFactoring'
    :source-data      = 'applicationObj.dataFields'
    :product          = 'factoringProduct'
    :third-party-list = 'applicationObj.applicationFields.thirdParties'
    @edit             = 'updateThirdParty'
    @create           = 'updateThirdParty'
    @remove           = 'updateThirdParty'
  )

  .card
    .card-header {{ $t('commentsTitle') }}
    .card-body
      fi-form-field.mb-1(
        :label     = '$t("customerComment")'
        label-cols = '3'
      )
        textarea.form-control.form-control-sm(
          v-model = 'applicationObj.customerComment'
          rows    = 3
        )
      fi-form-field.mb-1(
        :label     = '$t("internalComment")'
        label-cols = '3'
      )
        textarea.form-control.form-control-sm(
          v-model = 'applicationObj.internalComment'
          rows    = 3
        )
      button.btn.btn-primary(
        type      = 'submit'
        :disabled = 'submitDisabled'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      | &nbsp;
      button.btn.btn-secondary(
        type      = 'button'
        @click    = 'onCancel'
        :disabled = 'saving'
      ) {{ $t('common:cancel') }}
</template>


<script>
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { rules, validation, validators } from '@/validation'
import WorkflowPolicy from '@/policies/WorkflowPolicy'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import ApplicationFactoringDataFields
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringDataFormFields'
import ApplicationFactoringThirdPartyList
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringThirdPartyList'
import ApplicationCustomerFormFields from '@/views/applications/ApplicationCustomerFormFields'
import { classToClass, classToPlain, plainToClass } from 'class-transformer'
import { CApplication, CApplicationDataFields } from '@/models/application/CApplication.ts'
import VPrivateApplication from '@/validation/VPrivateApplication'
import VCorporateApplication from '@/validation/VCorporateApplication'
import ApplicationCorporateCustomerForm from '@/views/applications/ApplicationCorporateCustomerForm'
import { CThirdParty } from '@/models/application/CThirdParty.ts'

export default {
  name: 'application-new-factoring',

  components: {
    ApplicationCorporateCustomerForm,
    ApplicationCustomerFormFields,
    ApplicationFactoringThirdPartyList,
    ApplicationFactoringDataFields,
    FiMultiselect,
    FiFormField,
    FiSelect,
    FiPhoneInput
  },

  mixins: [validation],

  props: {
    workflowPolicy: {
      type: WorkflowPolicy,
      required: true
    },
    copy: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      idDocExpireDate: '',
      applicationObj: plainToClass(
        CApplication,
        {},
        {
          groups: this.workflowPolicy.groups,
          strategy: 'exposeAll'
        }
      )
    }
  },

  computed: {
    ...mapState('applications', ['application', 'applicationCustomer']),
    ...mapGetters('customers', ['customerName', 'isCorporateCustomer']),
    ...mapGetters('applications', ['customerDataForApplication']),
    ...mapGetters('classifiers', [
      'classifierByName',
      'classifierById',
      'optionsFromClassifier',
      'optionsFromCountiesByCountry',
      'classifierList',
      'productTypeById',
      'optionsFromCreditIssuers'
    ]),
    ...mapGetters('products', ['optionsFromProductsBySectorAndSegment']),
    ...mapState('settings', ['settings']),
    ...mapGetters('settings', ['isLimitEnabled']),
    ...mapGetters('factoring', ['factoringProductAttributes', 'factoringProductFees']),
    ...mapState('factoring', ['factoringProduct']),
    customerFields () {
      return this.workflowPolicy.privateCustomer ? this.applicationObj.privateApplication : this.applicationObj.corporateApplication
    },
    creditIssuer () {
      return this.optionsFromCreditIssuers.find(({ value }) => value === this.factoringProduct.creditIssuerId)
    },
    allowedProductOptions () {
      const allowedIds = this.workflowPolicy?.productIds
      const { sectorId, segmentId } = this.customerFields
      if (!allowedIds) {
        return
      }
      return this.optionsFromProductsBySectorAndSegment({ sectorId, segmentId }).filter(({ value }) => allowedIds.includes(value))
    },
    allowedSectors () {
      return this.optionsFromClassifier('sectors').filter(item => item.value === this.factoringProduct.sectorId)
    },
    allowedSegments () {
      return this.factoringProduct.segments?.map(({ id }) => ({ value: id, text: this.classifierById('segments', id).human })) ?? this.optionsFromClassifier('segments')
    },
    saving () {
      return this.$vueLoading.isLoading('application:save')
    },
    submitDisabled () {
      return this.saving || !(this.workflowPolicy.allowNewCustomer || this.customerFields.customerId)
    },
    productLoading () {
      return this.$vueLoading.isLoading('factoring:product:fetch')
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.customerFields.countryId)
    }
  },

  watch: {
    async 'applicationObj.applicationFields.productId' (value) {
      if (value) {
        await this.loadProduct({ id: value })
        this.processProduct()
      } else {
        this.SET_APPLICATION_PRODUCT({ product: {} })
      }
      this.$v.$reset()
    }
  },

  validations () {
    const mergedRules = { ...rules.applicationRules, ...rules.customerRules }

    let customerFields = {
      firstName: {
        required: validators.requiredIf(this.workflowPolicy.privateCustomer)
      },
      lastName: {
        required: validators.requiredIf(this.workflowPolicy.privateCustomer)
      },
      customerName: {
        required: validators.requiredIf(!this.workflowPolicy.privateCustomer)
      },
      naceId: {},
      emtakId: {}
    }

    const applicationFields = {
      ...pick(mergedRules, Object.keys(this.applicationObj.applicationFields)),
      thirdParties: {
        $each: {
          ...customerFields,
          limit: {},
          advanceRate: {},
          invoiceLengthMax: {},
          invoiceLengthMin: {},
          invoiceLengthInterestRates: {}
        }
      }
    }

    if (this.workflowPolicy.privateCustomer) {
      customerFields = {
        privateApplication: VPrivateApplication
      }
    } else {
      customerFields = {
        corporateApplication: VCorporateApplication
      }
    }

    const dataFields = {
      ...pick(mergedRules, Object.keys(this.applicationObj.dataFields)),
      creditAmount: {
        required: true
      },
      loanLength: {
        gte: validators.gte(this.factoringProduct.periodMin),
        lte: validators.lte(this.factoringProduct.periodMax)
      }
    }

    return {
      applicationObj: {
        applicationFields,
        dataFields,
        ...customerFields
      }
    }
  },

  async created () {
    await this.loadProducts({ group: this.workflowPolicy.productType.group })
    if (this.allowedProductOptions.length === 1) {
      const [product] = this.allowedProductOptions
      this.applicationObj.applicationFields.productId = product.value
    } else {
      this.SET_APPLICATION_PRODUCT({ product: {} })
    }

    if (this.customerId) {
      this.customerFields.customerId = this.customerId
    }
    if (this.copy) {
      this.applicationObj = plainToClass(
        CApplication,
        this.application,
        {
          groups: this.workflowPolicy.groups,
          strategy: 'exposeAll'
        }
      )
    }
  },

  methods: {
    ...mapActions('products', ['loadProducts']),
    ...mapMutations('applications', ['SET_APPLICATION_PRODUCT']),
    ...mapActions('applications', ['createApplication', 'loadApplicationCustomer']),
    ...mapActions('factoring', ['loadProduct']),
    processProduct () {
      if (this.copy) return
      this.applicationObj.dataFields = plainToClass(CApplicationDataFields, {
        ...this.applicationObj.dataFields,
        advanceRate: this.factoringProductAttributes?.ADVANCE_RATE?.defaultValue,
        contractLimit: this.factoringProductAttributes?.AMOUNT_LIMIT?.defaultValue,
        loanLength: this.factoringProductAttributes?.AGREEMENT_LENGTH.defaultValue,
        invoiceLengthMin: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.minValue,
        invoiceLengthMax: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.maxValue,
        invoiceLengthInterestRates: [{
          invoiceLength: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.defaultValue,
          periodInterestRate: undefined,
          yearlyInterestRate: this.factoringProductAttributes?.INTEREST_RATE?.defaultValue
        }]
      }, { groups: this.workflowPolicy.groups, exposeUnsetFields: true })
    },
    async onSubmit () {
      if (this.validate()) {
        await this.createApplication({
          applicationData: classToPlain(this.applicationObj, {
            groups: this.workflowPolicy.groups,
            excludeExtraneousValues: true,
            exposeUnsetFields: false
          })
        })
        this.$router.push({ name: 'Application', params: { id: this.application.id } })
      }
    },
    onCancel () {
      this.$router.push({ name: 'Applications' })
    },
    applyToDebtors ({ contractLimit: limit, advanceRate, invoiceLengthMin, invoiceLengthMax }) {
      this.applicationObj.applicationFields.thirdParties = plainToClass(CThirdParty, this.applicationObj.applicationFields.thirdParties.map(thirdParty => ({
        ...thirdParty,
        limit,
        advanceRate,
        invoiceLengthMax,
        invoiceLengthMin
      })))
    },
    updateThirdParty ({
      index = -1,
      thirdParty,
      done
    } = {}) {
      const newThirdPartiesList = classToClass(this.applicationObj.applicationFields.thirdParties)

      if (typeof thirdParty === 'undefined') {
        newThirdPartiesList.splice(index, ~index && 1)
      } else {
        newThirdPartiesList.splice(index, ~index && 1, thirdParty)
      }

      this.applicationObj.applicationFields.thirdParties = newThirdPartiesList
      done && done()
    },
    selectCustomer (customer) {
      const {
        privateCustomer: {
          idDocExpireDate,
          idDocId,
          idDocNumber
        } = {}
      } = customer
      if (this.workflowPolicy.privateCustomer) {
        this.applicationObj.privateApplication.idDocId = idDocId
        this.applicationObj.privateApplication.idDocNumber = idDocNumber
        this.idDocExpireDate = idDocExpireDate
      }
    }
  }
}
</script>


<i18n>
en:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  commentsTitle:      "Comments"
  dataTitle:          "Application data"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  thirdPartyRequired: "At least one third-party is required"
et:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  commentsTitle:      "Comments"
  dataTitle:          "Application data"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  thirdPartyRequired: "At least one third-party is required"
ru:
  title:              "New application"
  product:            "Product"
  productPlaceholder: "Select product"
  creditIssuer:       "Credit issuer"
  customerTitle:      "Customer data"
  commentsTitle:      "Comments"
  dataTitle:          "Application data"
  customerComment:    "Client's comment"
  internalComment:    "Internal note"
  thirdPartyRequired: "At least one third-party is required"
</i18n>
